import axios from 'axios';
import { ReportEvent } from "./enum";
import { useUser } from './useUser';
import { useQuery } from './useQuery';
import { useDevice } from './useDevice';
import { AesEncode } from './useEncrypt';
const { userInfo } = useUser();
const { query } = useQuery();
const { deviceInfo } = useDevice();
let _logOrder = 1;
/** 打点上报 */
const _doReport = (params) => {
    let key = query.key;
    let ext = encodeURIComponent(AesEncode(JSON.stringify(params)));
    let url = process.env.VUE_APP_NAP_H5_SVR_URL_GO + `?pid=${PACKAGE_NAME}&key=${key}&etype=1&ext=${ext}&t=1.gif&ec=1`;
    axios.get(url);
};
/** 通用打点 */
const report = (options) => {
    let { AppName, Model, OSType, DeviceType, Brand, OsVersion } = deviceInfo;
    let { uuid, sessionId, appLaunchId } = userInfo;
    let { channel, subchannel, key } = query;
    let params = Object.assign({ AppLaunchId: sessionId || '', PackageName: PACKAGE_NAME, AppSession: appLaunchId, PageSession: '', AppName: AppName, AppType: APP_TYPE, AppVersion: APP_VERSION_NAME, SubChannel: subchannel, Channel: channel, uuid: uuid || '', Model: Model, Brand: Brand, OSType: OSType, region: window.navigator.language || '', DeviceType: DeviceType, ScreenWidth: window.screen.width, ScreenHeight: window.screen.height, windowWidth: window.screen.availWidth, windowHeight: window.screen.availHeight, OsVersion: OsVersion, Order: _logOrder }, options);
    _doReport(params);
    _logOrder += 1;
};
/** 应用启动 */
const reportLaunch = () => {
    let params = {
        EVENT: ReportEvent.AppLaunch,
    };
    report(params);
};
/** 进入页面打点 */
const reportEnterPage = (pageName) => {
    let params = {
        EVENT: ReportEvent.EnterPage,
        PageName: pageName,
    };
    report(params);
};
/** 异常打点 */
const reportExp = (expName, err) => {
    let params = {
        EVENT: ReportEvent.Exception,
        ActionName: expName,
        MSG: JSON.stringify(err),
    };
    report(params);
};
export function useReport() {
    return { report, reportLaunch, reportEnterPage, reportExp };
}
