const Inter = [
  {
    space_src: "baidujs",
    space_type: "inters",
    space_id: "u6943896",
    space_js: "//sjgfzpgb.timesaurora.com/production/x/common/sra/static/o/kuar.js"
  }
];

const Native1 = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6943897",
    space_js: "//sjgfzpgb.timesaurora.com/production/y/openjs/ts/b/production/plvbu.js"
  }
];

const Banner = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6943898",
    space_js: "//sjgfzpgb.timesaurora.com/production/av_ud/rnx_d/static/x.js"
  }
];

export default {
  Home_inter: Inter,
  Home_native: Native1,
  Home_banner: Banner,
  Detail_inter: Inter,
  Detail_native: Native1,
  Detail_banner: Banner,
  List_native: Native1,
  List_banner: Banner,
  Result_inter: Inter,
  Result_native: Native1,
  Result_banner: Banner
};
